<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('institutions.title') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <DatatableDropdown />
              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link :to="{ name: 'InstitutionsNew' }" class="btn btn-primary font-weight-bolder">
                <span class="svg-icon svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
                {{ $t('institutions.new_title') }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <Datatable
                ref="datatable"
                :url="datatableUrl"
                :dataUrl="datatableDataUrl"
                @data="loadDatatableData($event)"
            >
                <!-- <template v-slot:head>
                    Table head özelleştirme
                </template> -->
                <template v-slot:body>
                  <tr v-for="(item, index) in datatableData" :key="'data-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td>{{ item.name }}</td>
                    <td>{{ (item.country_id) ? item.country_id.toUpperCase() : item.country_id }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ (item.parent_id) ? capitalizeFirstLetter(item.parent_id): item.parent_id }}</td>
                    <td>{{ (item.status) ? $t(`service.${item.status}`) : item.status }}</td>
                    <td>
                      <router-link v-if="checkPermission('crm.institutions.edit')" :to="`/institutions/update/${item.id}`" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.edit')">
                        <i class="la la-pencil"></i>
                      </router-link>
                      <button v-if="checkPermission('crm.institutions.destroy')" type="button" @click="deleteItem(item.id)" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.delete')">
                        <i class="la la-trash"></i>
                      </button>
                      <button @click.prevent="downloadPDF(item.name, item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('commons.file_download')">
                        <i class="far fa-file-pdf fs-4"></i>
                      </button>
                    </td>
                  </tr>
                </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";
import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import $ from "jquery";

export default {
  name: "Institutions",
  data() {
    return {
      isLoading:true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    Datatable,
    DatatableDropdown
  },
  computed: {
    ...mapState({
        datas: state => state.institutions.institutions,
        permissions: (state) => state.customer.permissions,
        lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [
          this.$t('institutions.title')
        ]
      }
    }
  },
  methods: {
    ...mapActions('institutions', ['DELETE_INSTITUTIONS']),
    deleteItem(id) {
      const vm= this;
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel'),
      }).then(function (result) {
        if (result.value) {
          vm.DELETE_INSTITUTIONS(id).then(() => {
            vm.initDatatable();
          })
        }
      });
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    downloadPDF(name, id) {
      let result = name.substr(0, 5);
      result += name.length>5 ? '..' : '';
      let data = { url: `institutions/${id}/get-agreement-pdf`, label: `Contract_${result}.zip`, type: "application/zip" };
      this.$store.dispatch('global/GET_MULTI', data);
    },
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.institutions.index')) {
      this.$router.push('/no-permission')
    }
    this.datatableUrl = api_old.inst_datatable;
    this.datatableDataUrl = api_old.inst_datatable_data;
    const self = this;
    self.$nextTick(function () {
      $("#printExportItem").hide();
      $("#copyExportItem").hide();
      $("#excelExport").on("click", function (e) {
        e.preventDefault();
        let data = { url: `institutions/management-export`, label: `institutions.xlsx`, reqPayload: self.$refs.datatable.selectedFilters };
        self.$store.dispatch('global/DOWNLOAD_XLSX_POST', data);
      });
      $("#csvExportItem").hide();
      $("#pdfExportItem").hide();
    });
  },
}
</script>
<style lang="scss">

</style>
